@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind screens;
@import url('./icomoon/style.css');
@font-face {
    font-family: 'Atkinson Hyperlegible Bold';
    src: url('../assets/fonts/Atkinson/WOFF2/Atkinson-Hyperlegible-Bold-102a.woff2') format('woff2'), url('../assets/fonts/Atkinson/WOFF/Atkinson-Hyperlegible-Bold-102.woff') format('woff'), url('../assets/fonts/Atkinson/TTF/Atkinson-Hyperlegible-Bold-102.ttf') format('truetype'), url('../assets/fonts/Atkinson/EOT/Atkinson-Hyperlegible-Bold-102.eot?#iefix') format('embedded-opentype'), url('../assets/fonts/Atkinson/SVG/Atkinson-Hyperlegible-Bold-102.svg') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: 'Atkinson Hyperlegible Regular';
    src: url('../assets/fonts/Atkinson/WOFF2/Atkinson-Hyperlegible-Regular-102a.woff2') format('woff2'), url('../assets/fonts/Atkinson/WOFF/Atkinson-Hyperlegible-Regular-102.woff') format('woff'), url('../assets/fonts/Atkinson/TTF/Atkinson-Hyperlegible-Regular-102.ttf') format('truetype'), url('../assets/fonts/Atkinson/EOT/Atkinson-Hyperlegible-Regular-102.eot?#iefix') format('embedded-opentype'), url('../assets/fonts/Atkinson/SVG/Atkinson-Hyperlegible-Regular-102.svg') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: 'gotham-medium';
    font-weight: 400;
    src: url(../assets/fonts/Gotham//GothamRoundedMedium_21022.ttf) format('truetype');
}

@font-face {
    font-family: 'gotham-bold';
    font-weight: 600;
    src: url(../assets/fonts/Gotham/GothamRoundedBold_21016.ttf) format('truetype');
}

@font-face {
    font-family: 'gotham-book';
    font-weight: 600;
    src: url(../assets/fonts/Gotham/GothamRoundedBook_21018.ttf) format('truetype');
}

@font-face {
    font-family: 'gotham-ligth';
    font-weight: 100;
    src: url(../assets/fonts/Gotham/GothamRoundedLight_21020.ttf) format('truetype');
}


/* fallback */

@font-face {
    font-family: 'Material Icons';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/materialicons/v92/flUhRq6tzZclQEJ-Vdg-IuiaDsNcIhQ8tQ.woff2) format('woff2');
}

body {
    color: #2c2c2c;
    font-family: 'Atkinson Hyperlegible Regular', Arial, Helvetica, sans-serif;
}

.material-icons {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -moz-osx-font-smoothing: grayscale;
}

@font-face {
    font-family: 'Material Icons Outlined';
    font-style: normal;
    font-weight: 400;
    font-display: block;
    src: url('../assets//fonts//material/material-icons-outlined.woff2') format('woff2'), url('../assets//fonts//material/material-icons-outlined.woff2') format('woff2');
}

.material-icons-outlined {
    font-family: 'Material Icons Outlined';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    font-feature-settings: 'liga';
}

::-webkit-scrollbar {
    width: 20px;
    height: 20px;
    border-top: 0px solid #eee;
    background-color: transparent;
    border-left: 0px solid #eee;
}

::-webkit-scrollbar-thumb {
    height: 3px;
    border: 7px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    background-color: #ccc;
    -webkit-box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05), inset 1px 1px 0px rgba(0, 0, 0, 0.05);
}

::-webkit-scrollbar-button {
    width: 0;
    height: 0;
    display: none;
}

::-webkit-scrollbar-corner {
    background-color: transparent;
}

.Dropdown::-webkit-scrollbar {
    width: 20px;
    height: 20px;
    border-top: 0px solid #eee;
    background-color: transparent;
    border-left: 0px solid #eee;
}

.Dropdown::-webkit-scrollbar-thumb {
    height: 3px;
    border: 7px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    background-color: #ccc;
    -webkit-box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05), inset 1px 1px 0px rgba(0, 0, 0, 0.05);
}

.Dropdown::-webkit-scrollbar-button {
    width: 0;
    height: 0;
    display: none;
}

.Dropdown::-webkit-scrollbar-corner {
    background-color: transparent;
}

.tw-text-xxs {
    font-size: 10px;
}

.tw-text-xxxs {
    font-size: 8px;
}

.tw-text-xs {
    font-size: 12px;
}

.tw-text-sm {
    font-size: 14px;
}

.tw-text-base {
    font-size: 16px;
}

.tw-text-lg {
    font-size: 18px;
}

.tw-text-xl {
    font-size: 20px;
}

.tw-text-2xl {
    font-size: 22px;
}

.tw-text-3xl {
    font-size: 24px;
}

.tw-text-4xl {
    font-size: 26px;
}

.tw-text-5xl {
    font-size: 28px;
}

.tw-text-6xl {
    font-size: 30px;
}

.ag-theme-alpine {
    font-family: 'Atkinson Hyperlegible Regular', Arial, Helvetica, sans-serif;
    font-size: 12px;
}


/* estilos tabla aggrid*/

.ag-theme-alpine .ag-header {
    --ag-foreground-color: white;
    background-color: #2c2c2c;
}

.tw-text-7xl {
    font-size: 32px;
}

.tw-text-8xl {
    font-size: 34px;
}

.tw-text-9xl {
    font-size: 36px;
}

.ag-row.ag-row-level-1 {
    overflow: auto;
}