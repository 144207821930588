@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?37mt1t');
  src:  url('fonts/icomoon.eot?37mt1t#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?37mt1t') format('truetype'),
    url('fonts/icomoon.woff?37mt1t') format('woff'),
    url('fonts/icomoon.svg?37mt1t#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-ic_acceso_catalogo_alertas:before {
  content: "\e900";
}
.icon-ic_acceso_catalogo_facturas:before {
  content: "\e901";
}
.icon-ic_acceso_catalogo_pedidos_encargos:before {
  content: "\e902";
}
.icon-ic_acceso_catalogo_seguimiento:before {
  content: "\e903";
}
.icon-ic_add_emoji:before {
  content: "\e904";
}
.icon-ic_add_photo:before {
  content: "\e905";
}
.icon-ic_add_units:before {
  content: "\e906";
}
.icon-ic_arrow_hero:before {
  content: "\e907";
}
.icon-ic_bullet_off-1:before {
  content: "\e908";
}
.icon-ic_bullet_off:before {
  content: "\e909";
}
.icon-ic_cesta_aadir:before {
  content: "\e90a";
}
.icon-ic_cesta_llena:before {
  content: "\e90b";
}
.icon-ic_cesta_vacia:before {
  content: "\e90c";
}
.icon-ic_check_all:before {
  content: "\e90d";
}
.icon-ic_check_indeterminate:before {
  content: "\e90e";
}
.icon-ic_check_off:before {
  content: "\e90f";
}
.icon-ic_check_on:before {
  content: "\e910";
}
.icon-ic_common_arrow:before {
  content: "\e911";
}
.icon-ic_common_close:before {
  content: "\e912";
}
.icon-ic_common_search:before {
  content: "\e913";
}
.icon-ic_delete:before {
  content: "\e914";
}
.icon-ic_download:before {
  content: "\e915";
}
.icon-ic_enviar_suerencia:before {
  content: "\e916";
}
.icon-ic_fav_off:before {
  content: "\e917";
}
.icon-ic_fav_on:before {
  content: "\e918";
}
.icon-ic_filtro_laboratorio:before {
  content: "\e919";
}
.icon-ic_footer_facebook:before {
  content: "\e91a";
}
.icon-ic_footer_instagram:before {
  content: "\e91b";
}
.icon-ic_footer_youtube:before {
  content: "\e91c";
}
.icon-ic_image_full:before {
  content: "\e91d";
}
.icon-ic_logout:before {
  content: "\e91e";
}
.icon-ic_menu_alertas:before {
  content: "\e91f";
}
.icon-ic_menu_aplicaciones:before {
  content: "\e920";
}
.icon-ic_menu_catalogo:before {
  content: "\e921";
}
.icon-ic_menu_facturas:before {
  content: "\e922";
}
.icon-ic_menu_pedidos:before {
  content: "\e923";
}
.icon-ic_menu_seguimiento:before {
  content: "\e924";
}
.icon-ic_menu_sugerencias:before {
  content: "\e925";
}
.icon-ic_more_options:before {
  content: "\e926";
}
.icon-ic_paginador:before {
  content: "\e927";
}
.icon-ic_paso_1:before {
  content: "\e928";
}
.icon-ic_paso_2:before {
  content: "\e929";
}
.icon-ic_paso_3:before {
  content: "\e92a";
}
.icon-ic_radio_off:before {
  content: "\e92b";
}
.icon-ic_radio_on:before {
  content: "\e92c";
}
.icon-ic_rating:before {
  content: "\e92d";
}
.icon-ic_rest_units:before {
  content: "\e92e";
}
.icon-ic_seguimiento_entregado:before {
  content: "\e92f";
}
.icon-ic_seguimiento_preparacion:before {
  content: "\e930";
}
.icon-ic_seguimiento_reparto:before {
  content: "\e931";
}
.icon-ic_upload_file:before {
  content: "\e932";
}
.icon-ic_vadefarma:before {
  content: "\e933";
}
.icon-ic_ventaja_asistencia:before {
  content: "\e934";
}
.icon-ic_ventaja_novedades:before {
  content: "\e935";
}
.icon-ic_ventaja_ofertas:before {
  content: "\e936";
}
.icon-ic_ventaja_seguro:before {
  content: "\e937";
}
.icon-ic_ventaja_transporte:before {
  content: "\e938";
}
.icon-ic_vista_grid:before {
  content: "\e939";
}
.icon-ic_vista_lista:before {
  content: "\e93a";
}
